import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import mask from "@alpinejs/mask";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import MicroModal from 'micromodal';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/navigation';
Alpine.plugin(mask);
Livewire.start();
window.Echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
});
// document.addEventListener("DOMContentLoaded", () => {
//     const child = document.getElementById('tabs')?.firstElementChild;
//     child?.classList.add("border-b-2", "border-primary-500");
// })
document.addEventListener('livewire:navigated', () => {
    const child = document.getElementById('tabs')?.firstElementChild;
    child?.classList.add("border-b-2", "border-primary-500", "text-primary-500");

    new Swiper('.swiper-gallery', {
        modules: [Navigation],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        spaceBetween: 30,
    });
    new Swiper('.swiper-category', {
        slidesPerView: 'auto',
        spaceBetween: 8,
    });
    new Swiper('.swiper-banner', {
        autoHeight: true,
        slidesPerView: 1,
    });
    let swiper = new Swiper('.swiper-home', {
        autoHeight: true,
        slidesPerView: 1,
    });

    swiper.on('slideChange', function (slide) {
        let target = document.getElementById("tabs").children[slide.activeIndex];
        target.classList.add('text-primary-500', 'border-b-2', 'border-primary-500',);
        let nextSibling = target.nextElementSibling;
        while (nextSibling) {
            nextSibling.classList.remove('text-primary-500', 'border-b-2', 'border-primary-500');
            nextSibling.classList.add('text-dirly-gray');
            nextSibling = nextSibling.nextElementSibling;
        }
        let prevSibling = target.previousElementSibling;
        while (prevSibling) {
            prevSibling.classList.remove('text-primary-500', 'border-b-2', 'border-primary-500');
            prevSibling.classList.add('text-dirly-gray');
            prevSibling = prevSibling.previousElementSibling;
        }
    });
})

window.showTabs = function (child) {
    var parent = child.parentNode;
    var index = Array.prototype.indexOf.call(parent.children, child);
    let swiper = document.querySelector('.swiper-home').swiper;
    swiper.slideTo(index);
}

window.showPhotos = function (index) {
    const swiper = document.querySelector('.swiper-gallery').swiper;
    swiper.slideTo(index);
    MicroModal.show('modal-photos', { disableScroll: true, });
}

window.confirmStep2 = function (url) {
    if (confirm("Do you want to back") == true) {
        window.location.replace(url);
    }
}

window.readmore = function () {
    let content = document.querySelector(".readmore");
    content.classList.toggle("line-clamp-3");
    content.nextElementSibling.classList.toggle("hidden");
}

window.instagram = function () {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        location.href = 'http://instagr.am/p/';
        return;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        location.href = 'Instagram://camera';
        return;
    }
}

window.passcode = function (event) {
    let inputType = event.inputType;
    let el = event.target;

    if (inputType == 'deleteContentBackward' || !event.data) {
        el.previousElementSibling?.focus();
        el.value = null;
        return;
    }
    el.value = event.data;
    if (el.nextElementSibling) {
        el.nextElementSibling.value = null;
        el.nextElementSibling?.focus();
    }
    return;
}